import React from "react"

const IconInfo = () => {
  return (
    <svg 
      fill="none" 
      height="19" 
      viewBox="0 0 19 19" 
      width="19" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        clipRule="evenodd" 
        d="m16 9.5c0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5zm1 0c0 4.1421-3.3579 7.5-7.5 7.5-4.14214 0-7.5-3.3579-7.5-7.5 0-4.14214 3.35786-7.5 7.5-7.5 4.1421 0 7.5 3.35786 7.5 7.5zm-7.98899-3.58008h.98999v1.16h-.98999zm.98 2.08v4.23998h.91999v.76h-2.80999v-.76h.99v-3.47998h-.85v-.76z" 
        fill="#000" 
        fillRule="evenodd"/>
    </svg>
  )
}

export default IconInfo
