import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import IconInfo from "../components/iconInfo";

const Header = ({ siteTitle, artist, startYear, classOverride }) => (
  <header className={"header " + classOverride}>
    <div>
      <h1 style={{ margin: 0 }}>
        <Link to="/" className="header__link">
          {siteTitle}
        </Link>
      </h1>
      <div className="header__meta">
        <span>{artist}</span>
        <span>{", " + startYear}</span>
        <span>
          {" – " +
            new Date().toLocaleDateString() +
            " " +
            new Date().toLocaleTimeString()}
        </span>
        <Link to="/information" className="header__information">Information <IconInfo/> </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  classOverride: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  classOverride: ``,
}

export default Header
